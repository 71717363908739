<template>
  <section id="final-users">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("finalUsers.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("finalUsers.subtitle") }}
        </h5>
      </b-col>
      <b-col
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-link
          :to="{
            name: 'final-users-wizard'
          }"
        >
          <b-button variant="primary">
            <span class="text-nowrap">{{ $t("administrators.add") }}</span>
          </b-button>
        </b-link>
      </b-col>
    </b-row>
    <b-card>
      <table-header
        :total="totalUsers"
        :per-page.sync="perPage"
        :search-query.sync="searchQuery"
        :call-method="'allVusers'"
        @export="exportTable"
      />
      <b-table
        ref="refUserListTable"
        :items="fetchUsers"
        :busy="isBusy"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        :empty-text="$t('emptyText')"
        responsive
        primary-key="id"
      >
        <template #table-busy>
          <div
            v-if="!exporting"
            class="text-center text-danger my-2"
          >
            <b-spinner class="align-middle mr-1" />
            <strong>{{ $t('load') }}...</strong>
          </div>
          <div
            v-else
            class="text-center my-2"
          >
            <div class="text-center">
              <feather-icon
                icon="ClockIcon"
                size="30"
              />
              <p id="cancel-label">
                {{ $t('exporting') }}
              </p>
              <ICountUp
                :delay="delay"
                :end-val="nExport"
                :options="options"
              />
              {{ $t('of') }}
              <ICountUp
                :delay="delay"
                :end-val="totalCount"
                :options="options"
              />
              {{ $t('usuarios') }}
            </div>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(username)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.node.firstName)"
                variant="light-success"
                :to="{
                  name: 'final-users-edit',
                  params: { id: data.item.node.id }
                }"
              />
            </template>
            <b-link
              :to="{
                name: 'final-users-edit',
                params: { id: data.item.node.id }
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.node.firstName }} {{ data.item.node.lastName }}
            </b-link>

            <b-link
              :to="{
                name: 'final-users-edit',
                params: { id: data.item.node.id }
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              <small class="text-muted">@{{ cleanUsername(data.item.node.username) }}</small>
            </b-link>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="MailIcon"
              size="18"
              class="mr-50"
            />
            <span class="align-text-top">{{ data.item.node.email }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(isActive)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.node.isActive)}`
            "
            class="text-capitalize"
          >
            <feather-icon
              :icon="data.item.node.isActive ? 'CheckIcon' : 'XIcon'"
              size="18"
              class="mr-50"
            />
            {{
              data.item.node.isActive
                ? $t("dataGeneric.active")
                : $t("dataGeneric.inactive")
            }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="float-right">
            <b-link
              class="ml-1"
              :to="{
                name: 'final-users-edit',
                params: { id: data.item.node.id }
              }"
            >
              <feather-icon
                icon="EditIcon"
                size="17"
                class="text-warning"
              />
            </b-link>
          </div>
        </template>
      </b-table>
      <table-footer
        v-model="currentPage"
        :from="dataMeta.from"
        :to="dataMeta.to"
        :total="totalUsers"
        :per-page="perPage"
      />
    </b-card>
  </section>
</template>

<script>
import store from '@/store'
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BLink,
  BSpinner,
  BMedia,
  BButton,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import axios from '@axios'
import {
  messageError, cleanUsername, showToast, isEmpty,
} from '@/store/functions'
import xlsExport from 'xlsexport'
import { getUserData } from '@/auth/utils'
import ICountUp from 'vue-countup-v2'
import userStoreModule from './userStoreModule'
import useFinalUserList from './useFinalUserList'
import TableFooter from '../../components/table/TableFooter.vue'
import TableHeader from '../../components/table/TableHeaderPers.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BLink,
    ICountUp,
    BMedia,
    BSpinner,
    BAvatar,
    BBadge,
    BButton,
    // My components
    TableFooter,
    TableHeader,
  },
  setup() {
    const {
      fetchUsers,
      deleteUser,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      resolveUserStatusVariant,
      // Extra Filters
      statusFilter,
    } = useFinalUserList()

    return {
      fetchUsers,
      deleteUser,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      resolveUserStatusVariant,

      // Extra Filters
      statusFilter,
      avatarText,
    }
  },
  data() {
    return {
      USER_APP_STORE_MODULE_NAME: 'final-user-list',
      userData: getUserData(),
      isBusy: false,
      exporting: false,
      dataToExport: [],
      nExport: 0,
      totalCount: 0,
      delay: 1000,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
    }
  },
  mounted() {
    if (!store.hasModule(this.USER_APP_STORE_MODULE_NAME)) store.registerModule(this.USER_APP_STORE_MODULE_NAME, userStoreModule)
  },
  beforeDestroy() {
    if (store.hasModule(this.USER_APP_STORE_MODULE_NAME)) store.unregisterModule(this.USER_APP_STORE_MODULE_NAME)
  },
  methods: {
    cleanUsername,
    confirmText(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteUser(id)
        }
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      const zeroWidthSpace = '\u200C'
      return `${zeroWidthSpace}${day}/${month}/${year}`
    },
    exportData(cursor = '', typeExport, dates = []) {
      const query = `
        {
          allVusers (client:"${this.userData.profile.client.id}",first:300, 
          after:"${cursor}",          
          ${!isEmpty(dates) ? `startDate: "${dates[0]}", endDate: "${dates[1]}"` : ''}){
            pageInfo {
              endCursor
              hasNextPage
            }
            totalCount
            edges {
              node {
                id
                username
                email
                firstName
                lastName
                isLegalWarning
                isAcceptAds
                isActive
                dateJoined
                lastLogin
                nif
                phone
              }
            }
          }
        }
      `
      axios
        .post('', {
          query,
        })
        .then(response => {
          messageError(response, this)
          const info = response.data.data.allVusers
          const page = info.pageInfo
          const { edges, totalCount } = info
          const nodes = edges.map(item => item.node)
          this.dataToExport = [...this.dataToExport, ...nodes]
          this.nExport = this.dataToExport.length
          this.totalCount = totalCount
          if (page.hasNextPage) {
            this.exportData(page.endCursor, typeExport, dates)
          } else {
            const dataTable = this.dataToExport.map(item => ({
              [this.$t('ID')]: item.id,
              [this.$t('User')]: item.username.replace(`${this.userData.profile.client.name}_`, ''),
              [this.$t('name')]: `${item.firstName} ${item.lastName}`,
              [this.$t('Email')]: item.email,
              [this.$t('acept')]: item.isLegalWarning,
              [this.$t('promoRecep')]: item.isAcceptAds,
              [this.$t('code.status')]: item.isActive ? this.$t('dataGeneric.active') : this.$t('dataGeneric.inactive'),
              [this.$t('dateRegistration')]: this.formatDate(item.dateJoined),
              'NIF/CIF/NIE': item.nif,
              [this.$t('finalUsers.Phone')]: item.phone,
            }))
            const f = new Date()
            const m = f.getMonth() + 1
            const name = `${this.$t('fileNameUserRegistered') + f.getFullYear() + (m < 10 ? (`0${m}`) : `${m}`)}${f.getDate()}`

            // eslint-disable-next-line new-cap
            const xls = new xlsExport(dataTable, this.$t('Users'))
            if (typeExport === 'XLS') xls.exportToXLS(`${name}.xls`)
            else xls.exportToCSV(`${name}.csv`)

            this.exporting = !this.exporting
            this.isBusy = !this.isBusy
          }
        }).catch(err => {
          console.log(err)
          showToast(this.$t('subtitles.translationError'), 0, this)
        })
    },
    exportTable(data) {
      this.dataToExport = []
      this.exporting = !this.exporting
      this.isBusy = !this.isBusy
      this.nExport = 0
      const dates = []
      let typeExport = ''
      if (Array.isArray(data)) {
        const [type, startDate, endDate] = data
        typeExport = type
        dates.push(startDate)
        dates.push(endDate)
      } else {
        typeExport = data
      }
      this.exportData('', typeExport, dates)
    },
  },
}
</script>
